'use client'
import { usePathname } from 'next/navigation'

import { BoxProps } from '@/components/atoms/Box/Box'

import styles from './styles.module.css'

const AuthFormSection = ({ children, ...props }: BoxProps) => {
  const pathname = usePathname()

  return (
    <section className={styles.formSection} data-pathname={pathname} {...props}>
      {children}
    </section>
  )
}

export default AuthFormSection
